import React, { useState } from "react";
import "./TestSecondary.css";
import ForgotPassword from "../../Components/LoginSignupPopUp/ForgotPassword/ForgotPassword";
import ConfirmWallet from "../../Components/LoginSignupPopUp/ConfirmWallet/ConfirmWallet";
import ConfirmationCode from "../../Components/LoginSignupPopUp/ConfirmationCode/ConfirmationCode";
function TestSecondary() {
  return (
    <div className="test-secondary-container">
      {/* <ConfirmationCode /> */}
    </div>
  );
}

export default TestSecondary;
